// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
/* @import '@fortawesome/fontawesome-pro/scss/brands.scss';
@import '@fortawesome/fontawesome-pro/scss/light.scss';
*/
@import '@fortawesome/fontawesome-pro/scss/regular.scss';


@media screen{
    .show-on-print{
      display:none;
    }
  }
  
  @media print {
    .show-on-print{
      display:block;
    }
  }
  
  // compatibility with bootstrap 4
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  
  .form-row > .col {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  label {
    margin-bottom: 0.5rem;
  }
  
  /* .navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  } */
